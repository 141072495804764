import React from "react"
import { Link } from "react-router-dom"

function Footer() {
  return (
    <footer className="footer footer-center p-10 bg-primary text-primary-content rounded">
      <div>
        <p>
          Copyright © 2021 - 2023 | All rights reserved by
          <a href="https://www.linkedin.com/in/khaled-alabssi/" target="_blank">
            <span className="link link-neutral mx-1 font-bold">Khaled Alabssi</span>
          </a>
        </p>
      </div>
    </footer>
  )
}

export default Footer
