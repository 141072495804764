import React, { useState } from "react"
import axios from "../api/axios"
import { useNavigate } from "react-router-dom"
import AdminResults from "./AdminResults"

function Admin() {
  const navigate = useNavigate()
  const [result, setResult] = useState([])
  const [participants, setParticipants] = useState([])
  const submitHandler = async (e) => {
    e.preventDefault()
    const password = e.target.password.value
    if (!password) {
      return
    } else {
      console.log("ordered")

      const data = await axios.post("/admin/checkAttendance", {
        password,
      })
      // data.status should be 201 or 400
      if (data.status === 200) {
        // navigate("/result")
        setParticipants(data.data.students)
        setResult(data.data.data)
      }
    }
  }
  if (participants.length > 0 && result.length > 0) {
    return (
      <div>
        <ul className="inline-block m-4">
          <li className="invisible">empty</li>
          {participants.map((i) => (
            <>
              <li>{i.name}</li>
              <hr />
            </>
          ))}
        </ul>
       
        <AdminResults result={result} participants={participants} />
        
        {/* {result.map((i) => (
          <ul className="inline-block m-4">
            <li className="">{i.name}</li>
            {i.students.map((j) => (
              <>
                <li className={j.arrivalTime ? "bg-success px-2" : "bg-warning px-2"}>
                  {j.arrivalTime ? j.arrivalTime : "Didn't show up"}
                </li>
                <hr />
              </>
            ))}
          </ul>
        ))} */}
      </div>
    )
  }
  return (
    <div className="card w-96 bg-primary text-primary-content">
      <div className="card-body">
        <h2 className="card-title">Login</h2>
        <p>
          Submit your password in order to gain access to the attendace table...
        </p>
        <div className="">
          <form className="flex flex-col space-y-2" onSubmit={submitHandler}>
            <input
              type="password"
              placeholder="Passwrod"
              className="input input-bordered w-full max-w-xs"
              name="password"
              required
              minLength={3}
            />

            <div className="card-actions justify-end">
              <button type="submit" className="btn btn-secondary">
                submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Admin
