import React, { useState } from "react";

function AdminResults({ result, participants }) {
  const [limit, setLimit] = useState(3);
  const [show, setshow] = useState(result.slice(result.length - limit));

  async function changeHandler(e) {
    await setLimit(Number(e.target.value));
    await setshow(result.slice(result.length - limit));
  }

  return (
    <>
      {show.map((i) => (
        <ul className="inline-block m-4">
          <li className="">{i.name}</li>
          {i.students.map((j) => (
            <>
              <li
                className={
                  j.arrivalTime ? "bg-success px-2" : "bg-warning px-2"
                }
              >
                {j.arrivalTime ? j.arrivalTime : "Didn't show up"}
              </li>
              <hr />
            </>
          ))}
        </ul>
      ))}

      <div className="absolute top-20 left-6">
        <label htmlFor="limit">Increase the number of days...</label>
        <input
          type="range"
          min={1}
          max={result.length - 1}
          onChange={changeHandler}
          name=""
          id=""
        />
      </div>
    </>
  );
}

export default AdminResults;
