import React from "react"
import axios from "../api/axios"
import {useNavigate} from 'react-router-dom'

function Teacher() {
  const navigate = useNavigate();
  const submitHandler = async (e) => {
    e.preventDefault()
    const topic = e.target.topic.value;
    const password = e.target.password.value;
    const teacherPassword = e.target.teacherPassword.value

    let name = new Date()
    name = name.toDateString();
    if (!topic || !password || !name || !teacherPassword) {
      return
    } else {
      console.log("ordered");

      const data = await axios.post('/instructor/createDay', { topic, password, name, teacherPassword })
      // data.status should be 201 or 400
      if (data.status === 201) {
        navigate('/thanks')

      }
    }
  }
  return (
    <div className="card w-96 bg-primary text-primary-content">
      <div className="card-body">
        <h2 className="card-title">Create new date!</h2>
        <p>By submitting the form, new date will be added for the students</p>
        <div className="">
          <form className="flex flex-col space-y-2" onSubmit={submitHandler}>
            <input
              type="text"
              placeholder="Topic"
              className="input input-bordered w-full max-w-xs"
              name="topic"
              required
              minLength={3}
            />
            <input
              type="text"
              placeholder="Attendance code"
              className="input input-bordered w-full max-w-xs"
              name="password"
              required
              minLength={3}
            />
            <input
              type="password"
              placeholder="Teacher password"
              className="input input-bordered w-full max-w-xs"
              name="teacherPassword"
              required
              minLength={3}
            />
            <div className="card-actions justify-end">
              <button type="submit" className="btn btn-warning">
                Create
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Teacher
