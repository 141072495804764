import React, { useState, useEffect } from "react"
import axios from "../api/axios"
import { useNavigate } from "react-router-dom"


function Student() {
  const [day, setDay] = useState([])
  const [sessionPassword, setSessionPassword] = useState(false)
  const navigate = useNavigate()

  const getData = async () => {
    try {
      let name = new Date()
      name = name.toDateString()
      const { data } = await axios.post("/student/getDay", { name })
      if (data) {
        setDay(data.day)
      }
      console.log(data.day.students)
    } catch (error) {
      console.log(error)
    }
  }

  const unlock = (e) => {
    e.preventDefault()
    if (!e.target.pass.value) {
      return
    } else {
      if (e.target.pass.value === day.password)
        
      setSessionPassword(!sessionPassword)
    }
    

  }

  const arrival = async (e) => {
    const name = day.name
    const student = e.target.value
    let time = new Date()
    const arrivalTime = time.toLocaleTimeString()
    const data = await axios.patch("/student/arrival", {
      name,
      student,
      arrivalTime,
    })
    
    if (data.status === 202) {
      setSessionPassword(false)
      navigate('/welcome')
    }
    
  }

  useEffect(() => {
    getData()
  }, [])

  if (day.length == 0) {
    return (
      <div className="card w-96 bg-primary text-primary-content ">
        <div className="card-body items-center text-center">
          <h2 className="card-title text-3xl">Wait for the teacher to create new topic!!!</h2>
          
        </div>
      </div>
    )
  }
  return (
    <div className="grid gap-8">
      <div className="stats shadow ">
        <div className="stat max-w-[10em] md:max-w-[20em] lg:max-w-[40em] xl:max-w-[60em] overflow-x-auto">
          <div className="stat-title">Topic</div>
          <div className="stat-value text-primary text-2xl">{day.topic}</div>
        </div>

        <div className="stat">
          <div className="stat-title">Date</div>
          <div className="stat-value text-secondary text-2xl">{day.name}</div>
        </div>
      </div>

      <form
        className={
          !sessionPassword ? "form-control justify-self-center" : "hidden"
        }
        onSubmit={unlock}
      >
        <div className="input-group">
          <input
            type="text"
            name="pass"
            placeholder="password…"
            className="input input-bordered"
          />
          <button className="btn ">unlock</button>
        </div>
      </form>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2">
        {day.students.map((i) => (
          <button
            disabled={!sessionPassword}
            className={i.inClass ? "btn btn-disabled" : "btn btn-warning"}
            value={i.name}
            key={i.name}
            onClick={arrival}
          >
            {i.name}
          </button>
        ))}
      </div>
    </div>
  )
}

export default Student
