import "./App.css"
import Navbar from "./components/Navbar"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Student from "./components/Student"
import Teacher from "./components/Teacher"
import Result from "./components/Result"
import Thanks from "./components/Thanks"
import Welcome from "./components/Welcome"
import Error from "./components/Error"
import Footer from "./components/Footer"
import Admin from "./components/Admin"

function App() {
  return (
    <div data-theme="emerald" className="flex flex-col h-screen">
      <BrowserRouter>
      <Navbar />
        <main className="bg-gray-200 flex-1 md:px-8 flex justify-center items-center">
          <Routes>
            <Route path="/result" element={<Result />} />
            <Route path="/teacher" element={<Teacher />} />
            <Route path="/thanks" element={<Thanks />} />
            <Route path="/welcome" element={<Welcome />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/result" element={<Result />} />
            <Route path="/" element={<Student />} />
            <Route path="/*" element={<Error />} />
            <Route path="/error" element={<Error />} />
          </Routes>
        </main>
      <Footer />
      </BrowserRouter>
    </div>
  )
}

export default App
