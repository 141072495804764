import React from 'react'
import {Link} from 'react-router-dom'

function Thanks() {
  return (
    <div
      className="hero h-full"
      style={{
        backgroundImage: `url("https://ik.imagekit.io/kaa/redi-attendance/welcome_5gAcWWCQw.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1666639680946")`,
      }}
    >
      <div className="card w-96 bg-neutral text-neutral-content bg-opacity-50">
        <div className="card-body items-center text-center">
          <h2 className="card-title text-3xl">Have a great teaching day!!!</h2>
          <div className="card-actions justify-end">
            <Link to={"/"} >
            <button className="btn btn-primary">Homepage</button></Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Thanks