import React from 'react'
import { Link } from "react-router-dom"

function Error() {
  return (
    <div
      className="hero h-full"
      style={{
        backgroundImage: `url("https://ik.imagekit.io/kaa/redi-attendance/error_u9prhRGewj.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1666641012476")`,
      }}
    >
      <div className="card w-96 bg-neutral text-neutral-content bg-opacity-50">
        <div className="card-body items-center text-center">
          <h2 className="card-title text-3xl">Something went wrong!!!</h2>
          <div className="card-actions justify-end">
            <Link to={"/"}>
              <button className="btn btn-primary">Homepage</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Error